import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const NavBar = ({ dark, presrolled, light }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [hamMenu, setHamMenu] = useState(false);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  useEffect(() => {
    document.querySelector('meta[name="theme-color"]').setAttribute(
      'content',
      hamMenu ? '#0053ED' : '#ffffff'
    );
  }, [hamMenu]);

  useEffect(() => {
    const handleScroll = () => {
      if (!presrolled) {
        setIsScrolled(window.scrollY > 50);
      } else {
        setIsScrolled(true);
      }
      document.querySelector('meta[name="theme-color"]').setAttribute(
        'content',
        window.scrollY > 50 ? '#0053ED' : '#ffffff'
      );
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (presrolled) {
      setIsScrolled(true);
    }
  }, [presrolled]);

  return (
    <>
      <div className="nav-region">
        <div className="support-nav">
          <div className="support-nav-left">
            <img src="/assets/usflag.png" alt="phone" />
            <p>Owned and Operated in the U.S.A</p>
          </div>
          <div className="support-nav-right">
            <Link to="/contact-us">Contact Us</Link>
            <Link to="mailto:support@schoolsensefundraising.com">Email Us</Link>
            <Link to="/app/onboarding">Login</Link>
          </div>
        </div>
        <nav className={`navbar no-js-scrolled ${isScrolled || dark ? 'scrolled' : ''}`}>
          <div className="nav-left">
            <Link to="/">
              {isScrolled ? (

                <>
                  {
                    light ? <img src="/assets/sslogoblacktext.png" alt="logo" /> :
                      <img src="/assets/sslogoblack.png" alt="logo" />
                  }
                </>
              ) : (
                <img src="/assets/sslogo.png" alt="logo" />
              )}
            </Link>

            <ul className="nav-items desktop-menu">
              <li><Link to="/">Home</Link></li>
              <li>
                <div className="hover-dropdown">
                  <Link>How It Works<i className="material-icons">arrow_drop_down</i></Link>
                  <div className="dropdown-content vivify fadeIn duration-200">
                    <div className="dropdown-grouper">

                      <Link to="/howItWorks/yourStudents">For Students</Link>
                      <Link to="/howItWorks/donorsParents">For Donors & Parents</Link>
                      <Link to="/howItWorks/yourSchool">For Your School</Link>

                    </div>
                  </div>
                </div>
              </li>
              <li><Link to="/contact-us">Contact Us</Link></li>
              <li><Link target="_blank" to="https://schoolsensefundraising.com/school/1/campaign/12/share?us=d">
                Try Demo Campaign
                <i className="material-icons">open_in_new</i>
              </Link></li>
            </ul>
          </div>
          <div className="nav-right">
            <div className="mobile-btn">
              <button onClick={() => setHamMenu(!hamMenu)} className={hamMenu ? 'ham-btn active-btn' : 'ham-btn'}>
                <i className="material-icons">{hamMenu ? 'close' : 'menu'}</i>
              </button>
            </div>
          </div>

          <div className="mobile-menu vivify fadeInBottom duration-300" style={{ display: hamMenu ? 'block' : 'none' }}>
            <div className="mobile-btn floating-btn">
              <button onClick={() => setHamMenu(!hamMenu)} className={hamMenu ? 'ham-btn active-btn' : 'ham-btn'}>
                <i className="material-icons">{hamMenu ? 'close' : 'menu'}</i>
              </button>
            </div>
            <ul className="nav-items">
              <li><Link to="/">Home</Link></li>
              <li>
                <div className="hover-dropdown">
                  <Link >How It Works<i className="material-icons">arrow_drop_down</i></Link>
                  <div className="dropdown-content vivify fadeIn duration-200">
                    <div className="dropdown-grouper">
                      <Link to="/howItWorks/yourStudents">For Students</Link>
                      <Link to="/howItWorks/donorsParents">For Donors & Parents</Link>
                      <Link to="/howItWorks/yourSchool">For Your School</Link>
                    </div>
                  </div>
                </div>
              </li>
              <li><Link to="/contact-us">Contact Us</Link></li>
              <li><Link to="https://schoolsensefundraising.com/school/1/campaign/12/share?us=d">Try Demo Campaign

              </Link></li>
              <li><Link to="/app/onboarding">Login</Link></li>
            </ul>
          </div>
        </nav>
      </div>
      {dark ? <div className="nav-spacer"></div> : null}
      <style>
        {
          light ?
            `

            
            @media screen and (min-width: 1130px) {
           .nav-items>li>a {
            color: black}
     
            .hover-dropdown>a {
            color: black}

            .hover-dropdown>a>i {
            color: black}
    

            }

            .navbar.scrolled {
            background-color: white;
            }
           
           


            
          ` : null
        }
      </style>
    </>


  );
};

export default NavBar;
