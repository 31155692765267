import { useEffect } from "react";
import { useState } from "react";
import { Link, NavLink, Outlet } from 'react-router-dom';


export const Cards = ({ obj, currentTab, cardClass }) => {
    return (
        <>
            {Object.keys(obj).map((key, index) =>

                obj[key].map(card => (        /*    reminder for me: could just pass in a whole class instead of the if word addition, or just have 2 seperate classes have 2 seperate classes if its passed in and have one class overwrite with !important */
                    card.link == null ? (
                        <div className={`card${cardClass == null ? '' : cardClass}`} style={{ display: currentTab == index ? "flex" : "none" }}>
                            {card.icon == "customIconStudent" ? (<img src="/assets/logoamc.png" alt="" id="icon2" />) : card.icon == "backgroundImage" ? (<img src={card.image} alt="" className="learnMoreImgs" />) : (<i className="material-icons">{card.icon}</i>)}
                            <h3 className={`cardTitle${cardClass == null ? '' : cardClass}`}>{card.title}</h3>
                            <div className={`cardText${cardClass == null ? '' : cardClass}`}>{card.text}</div>
                        </div>
                    ) : (



                    <Link to={card.link} className={`card${cardClass == null ? '' : cardClass}`} style={{ display: currentTab == index ? "block" : "none" }}>

                        {card.icon == "customIconStudent" ? (<img src="/assets/logoamc.png" alt="" id="icon2" />) : card.icon == "backgroundImage" ? (<img src={card.image} alt="" className="learnMoreImgs" />) : (<i className="material-icons">{card.icon}</i>)}
                        <h3 className={`cardTitle${cardClass == null ? '' : cardClass}`}>{card.title}</h3>
                        <div className={`cardText${cardClass == null ? '' : cardClass}`}>{card.text}</div>
                    </Link>
                    )
                ))

            )}
        </>
    );

};



const TabsSection = () => {


    const [currentTab, setCurrentTab] = useState(1);

    const tabData = {
        section1: [
            {
                icon: "qr_code",
                title: "Scan School Provided QR Code & Join Campaign",
                text: "Scan the QR code to join your school's campaign & personalize your profile."
            },
            {
                icon: "ios_share",
                title: "Share Campaign on Social Media",
                text: "Share your campaign on social media to raise awareness & receive donations."
            },
            {
                icon: "emoji_events",
                title: "Receive Prizes & Monitor Progress",
                text: "Receive prizes for reaching fundraising goals & monitor your progress on the leaderboard."
            }
        ],
        section2: [
            {
                icon: "campaign",
                title: "Create Your Campaign & Define Goals",
                text: "Work with us to create your campaign, set fundraising goals and offer prizes to students."
            },
            {
                icon: "customIconStudent",
                title: "Invite Students & Share Campaign on Socials",
                text: "Invite your students so they can personalize their profiles & share your campaigns on socials."
            },
            {
                icon: "leaderboard",
                title: "Monitor Campaign & Receive Payouts",
                text: "Monitor your campaign through our campaign portal & receive payouts for your campaign."
            }
        ],
        section3: [
            {
                icon: "link",
                title: "Join a Campaign a Student Invited You To",
                text: "Join a students campaign by visiting the campaign link they shared with you."
            },
            {
                icon: "payment",
                title: "Donate to Campaign Securely",
                text: "Donate to campaign using card, Apple Pay, or Google Pay. Donations are secure & encrypted."
            },
            {
                icon: "leaderboard",
                title: "Track Student Progress & Campaign Goals",
                text: "Visit the campaign page to track student progress & see how close they are to reaching their goals."
            }
        ]

    };




    const change1 = () => {


        setCurrentTab(0);

    };

    const change2 = () => {


        setCurrentTab(1)

    };

    const change3 = () => {



        setCurrentTab(2)
    };





    return (
        <>
            <div className="tabsSection">
                <div className="tabsTitle">
                    <h2 className="darkText">How It Works For </h2><h2 className="blueText">
                        {currentTab == 0 ? "Your Students" : currentTab == 1 ? "Your School" : currentTab == 2 ? "Donors & Parents" : null}</h2>
                </div>
                <div className="tabButtons">
                    <button onClick={change1} className={currentTab == 0 ? "tabButton tabButtonActive" : "tabButton"} id="tab1"><h4 id="tabText1">Students</h4></button>
                    <button onClick={change2} className={currentTab == 1 ? "tabButton tabButtonActive" : "tabButton"} id="tab2"><h4 id="tabText2">Your School</h4></button>
                    <button onClick={change3} className={currentTab == 2 ? "tabButton tabButtonActive" : "tabButton"} id="tab3"><h4 id="tabText3">Donors & Parents</h4></button>
                </div>
                <hr className="line" />

                <div className="cardRow">


                    {/*        {section1:[{icon:'', title:'', text:''}{}{}], section2:[], section3[]}              */}

                    <Cards obj={tabData} currentTab={currentTab} />



                </div>
            </div>





        </>
    );



}
export default TabsSection;