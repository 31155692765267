import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Footer = () => {



    return (
        <>

            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-logo">
                            <img src="/assets/sslogoblacktext.png" alt="logo" />

                            <p>
                                A platform that simplifies school fundraising by allowing schools to easily collect donations, engage students through personalized campaigns, and share their efforts via social media.
                            </p>
                        </div>
                        <div className="footer-links">
                            <div className="footer-link-group">
                                <h4>Operating Hours</h4>
                                <ul>
                                    <li>
                                        <span>Mon - Fri: 8am - 4pm</span>
                                    </li>
                                    <li>
                                        <span>Available Via Email</span>
                                    </li>

                                </ul>
                            </div>
                            <div className="footer-link-group">
                                <h4>Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/app/onboarding">Sign In</Link>
                                    </li>
                                    <li>
                                        <Link to="https://schoolsensefundraising.com/school/1/campaign/12/share?us=d">Try Demo</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-link-group">
                                <h4>How It Works</h4>
                                <ul>
                                    <li>
                                        <Link to="/howItWorks/yourSchool">For Schools</Link>
                                    </li>
                                    <li>
                                        <Link to="/howItWorks/donorsParents">For Donors & Parents</Link>
                                    </li>
                                    <li>
                                        <Link to="/howItWorks/yourStudents">For Students</Link>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>&copy; 2024 BCS Fundraising. All Rights Reserved.</p>
                        <ul>
                            <li>
                                <a href="/legal/PrivacyPolicy.pdf">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/legal/TermsAndConditions.pdf">Terms of Service</a>
                            </li>
                            <li>
                                <Link to="/legal">Do Not Sell or Share My Personal Information</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>



        </>
    )
}

export default Footer